import React from 'react';
import styles from './index.module.css';

export const Footer = () => {
  return (
    <>
      <div className={styles.br}/>
      <div className={styles.text}>
        © 2009- 2023 ООО «СтройЭнергоКом»
      </div>
    </>
  );
};