export const clearPhoneNumber = (number: string):string =>
  number
    .split(' ')
    .join('')
    .replace('(','')
    .replace(')','')
    .replace('+','');

export const clearCount = (count: string):string =>
  count
    .split(' ')
    .join('')
    .split('/')
    .join('')
    .split('-')
    .join('');