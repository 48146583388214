import { FC } from 'react';
// @ts-ignore
import { DefaultPlayer } from 'react-html5video';
import 'react-html5video/dist/styles.css';
import styles from './index.module.css';
import { useResize } from '../../hooks/useResize';

interface VideoProps {
  name: string,
  link: string,
  src: any,
}

export const Video: FC<VideoProps> = ({ name, src, link }) => {
  const {isScreenMd} = useResize();
  return (
    <div  className={`${styles.item} ${isScreenMd ? styles.mobile : ''}`}>
      <a href={link} target='_blank'>{name}</a>
      <DefaultPlayer
        controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}
        >
        <source src={src} type='video/mp4' />
        <track label='English' kind='subtitles' srcLang='en' src='http://source.vtt' default />
      </DefaultPlayer>
    </div>
  );

};