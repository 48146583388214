import { clearCount, clearPhoneNumber } from "../utils/clearString";
import { createStore } from "../utils/createStore";

interface IMainStore {
  step: number;
  setNextStep: () => void;
  setEndStep: () => void;
  clearStep: () => void;

  endText?: JSX.Element;
  setEndText: (endText: JSX.Element) => void;

  phone?: string;
  count?: string;
  code?: string;

  phoneErr?: string;
  countErr?: string;
  codeErr?: string;

  setCodeErr: () => void;
  clearCodeErr: () => void;

  clearPhone: () => void;
  clearCount: () => void;
  clearCode: () => void;

  setPhone: (str: string) => void;
  setCount: (str: string) => void;
  setCode: (str: string) => void;
}

export const useMainStore = createStore<IMainStore>(
  (set, get) => ({
    step: 0,
    setNextStep: () => set(() => ({step: get().step + 1})),
    setEndStep: () => set(() => ({step: 5})),
    clearStep: () => set(() => ({step: 0})),

    setEndText: (endText) => set(() => ({endText})),

    clearPhone: () => set(() => ({phone: undefined})),
    clearCount: () => set(() => ({count: undefined})),
    clearCode: () => set(() => ({code: undefined})),

    setPhone: (str) => set(() => ({
      phone: clearPhoneNumber(str),
      phoneErr: clearPhoneNumber(str).length < 11 ? 'Введите номер целиком' : undefined,
    })),
    setCount: (str) => set(() => ({
      count: str,
      countErr:
        (clearCount(str).length !== 10 && clearCount(str).length !== 11) ?
          'Лицевой счет должен содержать 10-11 цифр' : undefined,
    })),
    setCode: (str) => set(() => ({code: str})),

    setCodeErr: () => set(() => ({codeErr: 'Введен неверный код'})),
    clearCodeErr: () => set(() => ({codeErr: undefined})),

  }),
  'data'
);
