import axios, { AxiosResponse } from 'axios';
import { getDateString } from '../utils/helpers';

const BACKEND_BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

export async function sendSmsToPhone(phoneNumber: string): Promise<AxiosResponse<any, any>> {
  const url = `${BACKEND_BASE_URL}/sms-code/send?phoneNumber=${phoneNumber}`
  return axios.post(url, {})
}

export async function sendRequestToCallcenter(
  phoneNumber: string,
  accountingNumber:string,
  code: string,
): Promise<AxiosResponse<{ consumerClaimRequestId: string, address: string }, any>> {
  const url = `${BACKEND_BASE_URL}/consumer-claim`;
  return axios.post(url, { phoneNumber, accountingNumber, code })
}

export async function consumerClaim(consumerClaimRequestId: string): Promise<AxiosResponse<any, any>> {
  const url = `${BACKEND_BASE_URL}/consumer-claim/${consumerClaimRequestId}`;
  return axios.post(url, {})
}


export async function getCountInstallation(data?: Date): Promise<AxiosResponse<any, any>> {
  const tmpDate = data ? data : new Date();
  const url = `${BACKEND_BASE_URL}/replacement/count?date=${getDateString(tmpDate)}`;
  return axios.get(url)
}