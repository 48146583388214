import React, { useEffect, useState } from 'react';
import styles from './index.module.css';
import { getCountInstallation } from '../../api/api';
import { useResize } from '../../hooks/useResize';

const getSecondsToday = () => {
  let d = new Date();
  return d.getHours() * 3600 + d.getMinutes() * 60 + d.getSeconds();
}

export const Counter = () => {
  const [count, setCount] = useState<number>(1000);
  const {isScreenLg} = useResize();

  const getCount = () => {
    getCountInstallation()
      .then(({data}) => setCount(data))
      .catch(() => {
        setCount(1000 + Math.round(getSecondsToday() / 50))
      })
  }
  useEffect(getCount, [])
  // TODO: Update 5s
  // useEffect(() => {
  //   const timer = setInterval(getCount, 5000);
  //   return () => clearInterval(timer);
  // }, []);

  return (
    <div className={styles.wrapper} style={{width: isScreenLg ?  '100%': 'calc(34% - 60px)'}}>
      <div className={styles.text}>Каждый счетчик важен</div>
      <div className={styles.counter}>
        {count}
      </div>
      <div className={styles.text}>новых установок сегодня</div>
    </div>
  );
};
