import React, { FC } from 'react';
import { useResize } from '../hooks/useResize';

interface IMesIcon {
  className?: string | undefined;
}

const MesIcon:FC<IMesIcon> = ({className}) => {
  const {isScreenSm} = useResize();
  return (
    <div className={className} style={{width: isScreenSm? '27px' : '46px'}}>
      <svg viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2676_20887)">
          <path d="M6.688 6.69531C2.90661 10.487 0.572025 15.4925 0.0921329 20.8372C-0.38776 26.1819 1.01755 31.526 4.06254 35.9359C7.10753 40.3457 11.5986 43.541 16.7511 44.9633C21.9036 46.3856 27.3899 45.9445 32.2515 43.7173L29.2652 38.2328L42.9466 43.1213L6.688 6.69531Z" fill="#DF8844"/>
          <path d="M38.9188 39.0889C42.7047 35.2989 45.0432 30.2927 45.5252 24.9462C46.0073 19.5996 44.6022 14.2531 41.5557 9.84176C38.5092 5.43046 34.0153 2.23529 28.8601 0.81517C23.7049 -0.604952 18.2166 -0.159608 13.3553 2.0733L16.3416 7.55146L2.66016 2.65028L38.9188 39.0889Z" fill="#304673"/>
          <path d="M38.5772 24.1H36.0012C35.7045 25.2857 34.9785 25.9324 33.9304 25.9324C32.7308 25.9324 32.0364 25.0638 32.0364 23.5547C32.0364 21.9252 32.8192 19.6997 34.7449 19.6997C35.8876 19.6997 36.3864 20.2831 36.4179 21.3926H38.9434V21.1707C38.9434 18.9833 37.3397 17.582 34.8585 17.582C31.4681 17.582 29.4604 20.2577 29.4604 23.5801C29.4604 26.3953 31.0514 28.0184 33.7726 28.0184C34.9158 28.0849 36.0425 27.7198 36.9315 26.9949C37.8204 26.2699 38.4074 25.2374 38.5772 24.1ZM29.1511 22.0267C29.1511 19.2052 27.5664 17.5884 24.8452 17.5884C22.4272 17.5884 20.7162 18.8565 20.1038 21.0946H22.7428C22.8617 20.6822 23.1105 20.3195 23.4521 20.0612C23.7936 19.8028 24.2093 19.6626 24.6369 19.6617C25.7102 19.6617 26.3921 20.3655 26.531 21.6146H23.3742L22.9386 23.6308H26.3163C25.9691 24.8355 25.1862 25.9007 23.8477 25.9007C22.705 25.9007 22.1999 25.3111 22.1746 24.2015H19.6492V24.4297C19.6492 26.6172 21.2528 28.0121 23.7341 28.0121C27.1244 28.0121 29.1321 25.3364 29.1321 22.0204L29.1511 22.0267ZM20.1543 17.8103H16.4293L14.731 20.9298C14.019 22.1847 13.4614 23.5217 13.0705 24.9116C13.1294 23.8377 13.1062 22.7609 13.0011 21.6907L12.6791 17.8103H9.04875L6.95898 27.7838H9.41494L10.2736 23.5547C10.5262 22.331 10.7534 21.101 10.905 19.8646C10.905 20.9742 10.9618 22.0647 11.0691 23.1743L11.53 27.7838H13.5251L15.8863 23.2441C16.4672 22.1218 16.9723 20.9869 17.4584 19.8266C17.1238 21.0122 16.8271 22.2232 16.5682 23.4406L15.6212 27.7838H18.0708L20.1543 17.8103Z" fill="white"/>
        </g>
        <defs>
          <clipPath id="clip0_2676_20887">
            <rect width="46" height="46" fill="white"/>
          </clipPath>
        </defs>
      </svg>

    </div>
  );
};

export default MesIcon;